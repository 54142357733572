@import url('./fonts/Montserrat/stylesheet.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .banner-height {
        min-height: calc(100vh - theme('height.28'));
    }

    .tooltip-styles {
        border-radius: 0 !important;
        opacity: 1 !important;
        padding: 0.2rem 1rem !important;
    }

    .percentage-field {
        @apply relative;
    }
    .percentage-field::before {
        content: '%';
        @apply absolute right-0 bg-primary h-7 md:h-11 flex justify-center items-center text-[0.725rem] md:text-2xl px-2  text-tertiary-dark;
    }
    .percentage-field.tattoo::before{
        content:'% Tatuaje'
    }
    .percentage-field.piercing::before{
        content:'% Piercing'
    }
    .radio-btn {
        @apply border-1 md:border-2 text-secondary-dark border-secondary-dark outline-secondary-dark focus:ring-secondary-dark w-6 h-6 cursor-pointer;
    }

    .money {
        @apply relative;
    }
    .money::before {
        content: '$';
        @apply absolute left-0 lg:h-11 md:h-8 h-[2.1rem] flex justify-center items-center text-[0.725rem] md:text-base lg:text-2xl px-2  text-tertiary-dark;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    .btn-outline {
        @apply w-full inline-block border-secondary-dark py-1 md:py-2 px-2 md:px-4 text-center bg-primary text-2xl text-secondary-dark border-1 md:border-2 cursor-pointer;
    }

    .btn-outline-top {
        @apply w-full inline-block border-secondary-dark py-2 px-4 text-center bg-primary text-2xl text-secondary-dark border-1 md:border-t-2 cursor-pointer;
    }

    .btn-primary {
        @apply w-full inline-block border-1 md:border-2 border-secondary-dark py-2 px-4 text-primary text-2xl bg-secondary-dark text-center cursor-pointer;
    }
    
    .small {
        @apply text-xl;
    }

    .table-style-1 {
        @apply border-t-2 border-tertiary-dark;
    }
    .table-style-1 td + td {
        @apply border-l-2 border-tertiary-dark;
    }
    .table-style-1 tr {
        @apply border-b-2 border-tertiary-dark;
    }

    .table-border {
        --offset: 1rem;
        --border-width: 1px;
        box-shadow: 0 calc(var(--offset) + var(--border-width)) 0 calc(-1 * var(--offset)) rgb(126, 126, 126);
    }

    .table-border-small {
        --offset: 0.5rem;
        --border-width: 1px;
        box-shadow: 0 calc(var(--offset) + var(--border-width)) 0 calc(-1 * var(--offset)) rgb(126, 126, 126);
    }

    @media (min-width: 768px) {
        .table-border {
            --border-width: 2px;
        }
    }
    /* .table-style-2 {
        border-spacing: 1em 0;
        @apply border-separate;
    }
    .table-style-2 tr:first-child {
        @apply font-medium text-secondary-dark;
    }
    .table-style-2 tr:not(:first-child):not(:nth-child(2)) td {
        @apply text-tertiary-dark py-2 border-b-2 border-tertiary-dark;
    } */
}

/* @layer utilities { */
    /* .Toastify__toast--success {
    } */
    /* .Toastify__toast--error {
        aquí puedo cambiar el bg
    } */
    /* .Toastify__toast {
        @apply border-2 border-secondary-dark shadow-none rounded-none !important;
    }
    .Toastify__close-button {
        @apply hidden !important;
    }
    
    .Toastify__toast-body {
        @apply text-xl text-secondary-dark !important;
        font-family: 'Montserrat', sans-serif, system-ui !important;
    }
    
    .Toastify__progress-bar--success,
    .Toastify__progress-bar--error {
        @apply bg-teal-400 !important;
    } */
    
/* } */